<template>
  <div>
    <ProfileListAddv2
      :create-profile-active.sync="createProfileActive"
      :selectedPathwayCohort="pathwayCohortData"
      :redirectOnComplete="false"
      :enrolledStudentIds="enrolledStudentIds"
      @refetch-data="refetchStudents"
    />

    <student-transfer-to-session
      @refresh="refetchStudents"
      :is-add-session-open.sync="isTransferStudentOpen"
      :profile-data="selectedTransferProfile"
    />
    <profile-upload-document
      :is-upload-document-open.sync="upload.isUploadDocumentOpen"
      :profile="upload.profile"
      :document-category="upload.documentCategory"
      :document-type="upload.documentType"
      @refresh="refetchStudents"
    />

    <div class="d-flex align-items-center justify-content-between mb-75">
      <div class="d-flex align-items-center">
        <h3 class="mb-0 mr-50">Students</h3>
        <div
          v-if="$can('roster.profile.create')"
          @click="addStudent"
          class="d-flex align-items-center text-muted cursor-pointer"
          style="margin-top: 3px; margin-left: 3px"
        >
          <feather-icon icon="PlusCircleIcon" size="18" class="mr-25" />
          <span>Add Student</span>
        </div>
      </div>

      <b-button variant="primary" class="ml-75" @click="isCurrent = !isCurrent">{{
        isCurrent ? 'Show Reassigned' : 'Show Current'
      }}</b-button>
    </div>

    <div v-if="pathwayCohortData.profiles.length || !isCurrent">
      <div class="mb-75">
        <b-row>
          <b-col cols="12" sm="8" md="7">
            <div class="d-flex align-items-center justify-content-start flex-wrap flex-sm-nowrap" style="gap: 0.675rem">
              <b-form-input v-model="searchQuery" class="drop-shadow-default" placeholder="Search..." style="max-width: 20rem" />
            </div>
          </b-col>

          <b-col cols="12" sm="4" md="5" class="d-flex align-items-center justify-content-end"> </b-col>
        </b-row>
      </div>

      <b-card no-body class="mb-0 table-no-top">
        <b-table
          ref="refStudentsListTable"
          class="position-relative table-min-height"
          :items.sync="fetchProfiles"
          :fields="tableColumns"
          responsive
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          :sort-by-formatted="true"
          show-empty
          empty-text="No matching records found"
          @row-contextmenu="onRowContextMenu"
        >
          <!-- Column: multi select -->
          <template #head(multiSelect)="data" tdClass="checkbox">
            <b-form-checkbox :checked="isAllChecked" @change="(val) => setAllChecked(val)" class="p-0"></b-form-checkbox>
          </template>

          <!-- Column: Session -->
          <template #cell(multiSelect)="data">
            <b-form-checkbox
              :checked="isProfileSelected(data.item.id)"
              @change="(val) => updateProfileSelected(data.item.id, val)"
              class="p-0"
            ></b-form-checkbox>
          </template>

          <!-- Column: studentId -->
          <template #cell(studentId)="data">
            {{ data.item.student.myIpId }}
          </template>

          <!-- Column: Session -->
          <template #cell(name)="data">
            <div class="d-flex align-items-center" :id="`student-${data.item.student.id}`">
              <div @click="data.toggleDetails" style="margin-left: -5px" class="mr-50 cursor-pointer" v-if="data.item.session">
                <feather-icon icon="ChevronDownIcon" size="24" v-if="data.detailsShowing" />
                <feather-icon icon="ChevronRightIcon" size="24" v-if="!data.detailsShowing" />
              </div>
              <div class="d-flex align-items-center" style="gap: 0.625rem">
                <div class="d-flex flex-column" style="gap: 0">
                  <div
                    class="d-flex align-items-center font-weight-bold text-nowrap text-white mb-0"
                    style="font-size: 1.25rem; line-height: 1.75rem"
                  >
                    <div class="cursor-pointer" @click="$router.push({ name: 'apps-students-view', params: { id: data.item.student.id } })">
                      {{ data.item.student.fullName }}
                    </div>
                    <div v-if="data.item.reassignFrom" class="ml-50">
                      <feather-icon
                        @click="redirectToReassigned(data.item.reassignFrom, false)"
                        icon="InfoIcon"
                        class="text-warning cursor-pointer"
                        :id="`sr-${data.item.id}-reassign-${data.item.reassignFrom.id}-tooltip`"
                        style="margin-bottom: 2px; z-index: 9"
                      />
                      <b-tooltip
                        placement="right"
                        @click="redirectToReassigned(data.item.reassignFrom, false)"
                        :title="`Reassigned From ${data.item.reassignFrom.name}`"
                        class="cursor-pointer"
                        :target="`sr-${data.item.id}-reassign-${data.item.reassignFrom.id}-tooltip`"
                      />
                    </div>
                  </div>
                  <b-link
                    :to="{ name: 'apps-students-view', params: { id: data.item.student.id } }"
                    class="d-flex align-items-center text-muted"
                  >
                    <small style="font-size: 1.05rem; line-height: 1.35rem">{{ data.item.student.myIpId }}</small>
                  </b-link>
                </div>
              </div>
            </div>

            <student-popover :target-id="data.item.student.id" :data="data.item" />
          </template>

          <!-- Column: Session -->
          <template #cell(email)="data">
            {{ data.item.student.email }}
          </template>

          <!-- Column: Attributes - Program -->
          <template #cell(fundingSource)="data">
            <div class="d-flex align-items-center">
              <b-badge :variant="`light-${resolveProfileProgramVariant(data.item.attributes.fundingSource)}`" class="text-capitalize">
                {{ title(data.item.attributes.fundingSource) }}
              </b-badge>
            </div>
          </template>

          <!-- Column: Attributes - Payment Status -->
          <template #cell(paymentStatus)="data">
            <div class="d-flex align-items-center">
              <b-badge :variant="`${resolveProfilePaymentStatusVariant(data.item.attributes.paymentStatus)}`" class="text-capitalize">
                {{ title(data.item.attributes.paymentStatus) }}
              </b-badge>
            </div>
          </template>

          <!-- Column: Attributes - Uploaded Resume -->
          <template #cell(uploadedResume)="data">
            <feather-icon
              :id="`pathway-cohort-student-row-${data.item.id}-upload-resume-icon`"
              icon="UploadIcon"
              class="cursor-pointer mr-25"
              size="16"
              @click="startUpload(data.item, 'enrollment', 'resume')"
            />

            <b-tooltip
              title="Upload Resume"
              class="cursor-pointer"
              :target="`pathway-cohort-student-row-${data.item.id}-upload-resume-icon`"
            />

            <b-badge :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedResume)}`" class="text-capitalize">
              {{ title(data.item.attributes.uploadedResume) }}
            </b-badge>
          </template>

          <!-- Column: Attributes - Uploaded COE -->
          <template #cell(uploadedCoe)="data">
            <div class="d-flex align-items-center">
              <feather-icon
                :id="`pathway-cohort-student-row-${data.item.id}-upload-coe-icon`"
                icon="UploadIcon"
                class="cursor-pointer mr-25"
                size="16"
                @click="startUpload(data.item, 'enrollment', 'coe')"
              />

              <b-tooltip title="Upload COE" class="cursor-pointer" :target="`pathway-cohort-student-row-${data.item.id}-upload-coe-icon`" />

              <b-badge :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedCoe)}`" class="text-capitalize">
                {{ title(data.item.attributes.uploadedCoe) }}
              </b-badge>
            </div>
          </template>

          <!-- Column: Attributes - Uploaded Transcript -->
          <template #cell(uploadedTranscript)="data">
            <feather-icon
              :id="`pathway-cohort-student-row-${data.item.id}-upload-transcript-icon`"
              icon="UploadIcon"
              class="cursor-pointer mr-25"
              size="16"
              @click="startUpload(data.item, 'transcript', 'prior')"
            />

            <b-tooltip
              title="Upload Transcript"
              class="cursor-pointer"
              :target="`pathway-cohort-student-row-${data.item.id}-upload-transcript-icon`"
            />

            <b-badge :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedTranscript)}`" class="text-capitalize">
              {{ title(data.item.attributes.uploadedTranscript) }}
            </b-badge>
          </template>

          <!-- Column: Attributes - VA Submission -->
          <template #cell(submittedToVa)="data">
            <div class="d-flex align-items-center">
              <feather-icon
                :id="`pathway-cohort-student-row-${data.item.id}-update-submission`"
                icon="CheckCircleIcon"
                class="cursor-pointer mr-25"
                size="16"
                @click="markProfileAsSubmitted(data.item)"
                v-if="data.item.attributes.submittedToVa === 'no'"
              />

              <b-tooltip
                title="Mark as Submitted"
                class="cursor-pointer"
                :target="`pathway-cohort-student-row-${data.item.id}-update-submission`"
                v-if="data.item.attributes.submittedToVa === 'no'"
              />

              <b-badge :variant="`light-${resolveProfileYNVariant(data.item.attributes.submittedToVa)}`" class="text-capitalize">
                {{ title(data.item.attributes.submittedToVa) }}
              </b-badge>
            </div>
          </template>

          <!-- Column: Total Students -->
          <template #cell(createdAt)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">
                {{ moment(data.item.createdAt).format('L') }}
              </span>
            </div>
          </template>

          <!-- Column: Total Students -->
          <template #cell(sessionDate)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">
                {{ moment(data.item.sessionDate).format('L') }}
              </span>
            </div>
          </template>

          <!-- Column: Transfer of Credit -->
          <template #cell(toc)="data">
            <b-badge
              :variant="`light-${resolveProfileYNVariant(data.item.toc && data.item.toc.enabled ? 'yes' : 'no')}`"
              class="text-capitalize"
            >
              {{ title(data.item.toc && data.item.toc.enabled ? 'Yes' : 'No') }}
            </b-badge>
          </template>

          <!-- Column: Compliance Score -->
          <template #cell(compliance)="data">
            <template v-if="!data.item.compliance || !data.item.compliance.scores">
              <b-badge variant="danger">N/A</b-badge>
            </template>

            <template v-else>
              <b-badge
                :variant="`${resolveComplianceScoreVariant({
                  score: data.item.compliance.scores.overall,
                })}`"
                :id="`compliance-${data.item.id}`"
                class="text-capitalize"
                style="cursor: help"
              >
                {{ gradeScore({ score: data.item.compliance.scores.overall, percent: true }) }}
              </b-badge>

              <compliance-popover
                :target-id="data.item.id"
                :student-name="data.item.student.firstName"
                :compliance-data="data.item.compliance"
                :sections-data="data.item.session.sections"
              />
            </template>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <div v-if="!isCurrent">
              <b-badge
                variant="warning"
                class="text-capitalize cursor-pointer"
                :id="`pathway-cohort-student-row-${data.item.id}-reassigned-link`"
                @click="redirectToReassigned(data.item.reassignFrom)"
              >
                <feather-icon
                  icon="ExternalLinkIcon"
                  size="16"
                  v-if="data.item && data.item.session && $can(`roster.${data.item.session.type === 'pathway' ? 'cohort' : 'section'}.get`)"
                />
                Reassigned
              </b-badge>
              <b-tooltip
                v-if="data.item && data.item.session && $can(`roster.${data.item.session.type === 'pathway' ? 'cohort' : 'section'}.get`)"
                :title="`View Reassigned ${data.item.session.type === 'pathway' ? 'Cohort' : 'Section'}`"
                class="cursor-pointer"
                :target="`pathway-cohort-student-row-${data.item.id}-reassigned-link`"
              />
            </div>
            <b-dropdown variant="primary" class="dropdown-edit-button" v-else>
              <template #button-content>
                <b-badge :variant="`${resolveProfileStatusVariant(data.item.status)}`" class="text-capitalize">
                  {{ title(data.item.status) }}
                </b-badge>
              </template>

              <b-dropdown-item @click="openReasonModal(data.item, 'enrolled')">Enrolled</b-dropdown-item>
              <b-dropdown-item @click="openReasonModal(data.item, 'graduated')">Graduated</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="openReasonModal(data.item, 'withdrew_s1')">Withdrew S1</b-dropdown-item>
              <b-dropdown-item @click="openReasonModal(data.item, 'withdrew_s2')">Withdrew S2</b-dropdown-item>
              <b-dropdown-item @click="openReasonModal(data.item, 'withdrew_s3')">Withdrew S3</b-dropdown-item>
              <b-dropdown-item @click="openReasonModal(data.item, 'terminated')">Terminated</b-dropdown-item>
            </b-dropdown>
          </template>

          <!-- Column: Modality -->
          <template #cell(modality)="data">
            <b-badge :variant="`${resolveProfileLocationStatus(data.item.attributes.location)}`" class="text-capitalize">
              {{ title(data.item.attributes.location) }}
            </b-badge>
          </template>

          <!-- Column: Student Status -->
          <template #cell(studentStatus)="data">
            <b-badge :variant="`${resolveStudentStatusVariant(data.item.student.status)}`" class="text-capitalize">
              {{ data.item.student.status }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="primary" class="dropdown-edit-button">
              <template #button-content>
                <div class="p-50">
                  <feather-icon icon="MoreHorizontalIcon" size="21" />
                </div>
              </template>

              <template v-if="isCurrent">
                <b-dropdown-item v-if="$can('roster.profile.update')" @click="editRosterProfile(data.item)">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <span>Edit</span>
                    <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
                  </div>
                </b-dropdown-item>

                <b-dropdown-item v-if="$can('roster.cohort.student.transfer')" @click="openTransferSessionModal(data.item)">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <span>Transfer</span>
                    <feather-icon icon="MoveIcon" size="15" class="ml-50 text-muted" />
                  </div>
                </b-dropdown-item>

                <b-dropdown-item v-if="$can('roster.cohort.student.credit')" @click="openCourseToCModal(data.item, true)">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <span>Add Credit</span>
                    <feather-icon icon="CreditCardIcon" size="15" class="ml-50 text-muted" />
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.toc.enabled && $can('roster.cohort.student.credit')"
                  @click="openCourseToCModal(data.item, false)"
                >
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <span>Remove Credit</span>
                    <feather-icon icon="CreditCardIcon" size="15" class="ml-50 text-muted" />
                  </div>
                </b-dropdown-item>

                <b-dropdown-divider />

                <b-dropdown-item v-if="$can('roster.cohort.student.remove')" @click="removeFromCohort(data.item.session.id, data.item.id)">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <span>Remove</span>
                    <feather-icon icon="DeleteIcon" size="15" class="ml-50 text-muted" />
                  </div>
                </b-dropdown-item>
              </template>
              <template v-else>
                <b-dropdown-item v-if="$can('roster.profile.update')" @click="editRosterProfile(data.item)">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <span>Edit Roster Profile</span>
                    <feather-icon icon="EditIcon" size="15" class="ml-2 text-muted" />
                  </div>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="data.item && data.item.session && $can(`roster.${data.item.session.type === 'pathway' ? 'cohort' : 'section'}.get`)"
                  @click="redirectToReassigned(data.item.session)"
                >
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <span>View Reassigned {{ data.item.session.type === 'pathway' ? 'Cohort' : 'Section' }}</span>
                    <feather-icon icon="EyeIcon" size="15" class="ml-2 text-muted" />
                  </div>
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </template>

          <!--- Sections for Profile -->
          <template #row-details="row">
            <pathway-cohort-view-students-section-list :student-id="row.item.student.id" :profile-id="row.item.id" />
          </template>
        </b-table>
        <div class="mx-2 mb-1">
          <b-row>
            <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="mb-0 text-muted" id="student-list-statuses" style="cursor: help">{{ dataMeta.of }} Total Entries</span>
              <student-list-statuses target-id="student-list-statuses" :data="studentListStatistics" />
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
              <div class="mr-2 vue-select-up">
                <span class="mb-0 mr-75">Rows Per Page</span>
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block"
                  :open="true"
                />
              </div>
              <span class="mr-2 ml-lg-50 mb-0">Page {{ currentPage }} of {{ totalPageCount }}</span>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalProfiles"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-sm-0 ml-lg-50"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <vue-context ref="pathwayCohortViewStudentListMenu" class="mw-8" v-slot="{ data }">
        <template v-if="data">
          <li v-if="isCurrent">
            <a
              v-if="$can('roster.profile.update')"
              class="d-flex align-items-center justify-content-between"
              @click="editRosterProfile(data.item)"
            >
              <span>Edit</span>
              <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
            </a>
            <a
              v-if="$can('roster.cohort.student.transfer')"
              class="d-flex align-items-center justify-content-between"
              @click="openTransferSessionModal(data.item)"
            >
              <span>Transfer</span>
              <feather-icon icon="MoveIcon" size="15" class="ml-50 text-muted" />
            </a>
            <a
              v-if="$can('roster.profile.delete')"
              class="d-flex align-items-center justify-content-between"
              @click="removeStudentsFromPathwayCohort([data.item.id])"
            >
              <span>Remove</span>
              <feather-icon icon="DeleteIcon" size="15" class="ml-50 text-muted" />
            </a>
          </li>
          <li v-else>
            <a
              v-if="$can('roster.profile.update')"
              class="d-flex align-items-center justify-content-between"
              @click="editRosterProfile(data.item)"
            >
              <span>Edit Roster Profile</span>
              <feather-icon icon="Edit3Icon" size="15" class="ml-2 text-muted" />
            </a>
            <a
              v-if="data.item && data.item.session && $can(`roster.${data.item.session.type === 'pathway' ? 'cohort' : 'section'}.get`)"
              class="d-flex align-items-center justify-content-between"
              @click="redirectToReassigned(data.item.session)"
            >
              <span>View Reassigned {{ data.item.session.type === 'pathway' ? 'Cohort' : 'Section' }}</span>
              <feather-icon icon="EyeIcon" size="15" class="ml-2 text-muted" />
            </a>
          </li>
        </template>
      </vue-context>
    </div>

    <div v-else>
      <b-card>
        <div class="d-flex align-items-center justify-content-center flex-column py-2">
          <feather-icon icon="AlertTriangleIcon" size="36" class="mb-1 text-warning" />
          <h4 class="mb-50">No students found</h4>
          <p class="text-center mb-0">No students found for this pathway cohort</p>
        </div>
      </b-card>
    </div>

    <student-add-credit
      v-if="isAddCreditOpen"
      :cohort-data="pathwayCohortData"
      :selected-profile="selectedTransferProfile"
      :is-add-credit-open.sync="isAddCreditOpen"
      :is-assign="tocEdit.isAssign"
    ></student-add-credit>

    <!-- todo: remove TOC implementation from this page -->
    <b-modal
      v-if="false"
      id="modal-toc"
      ok-title="Submit"
      centered
      size="md"
      :title="`Please select a course to ${tocEdit.isAssign ? 'assign credit for' : 'remove credit from'}`"
      ref="modal"
      @hidden="() => (tocEdit.isModalOpen = false)"
      :visible="tocEdit.isModalOpen"
      @ok="() => submitProfileToC()"
      :ok-disabled="!tocEdit.section || (tocEdit.isAssign && (!tocEdit.note || tocEdit.note.length === 0))"
      :no-close-on-backdrop="true"
    >
      <b-card>
        <b-row>
          <b-col cols="12">
            <b-form-group label="ToC Course" label-for="toc-course">
              <v-select v-model="tocEdit.section" :options="tocSectionOptions" :clearable="false" />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group v-if="tocEdit.isAssign" label="ToC Notes" label-for="toc-notes">
              <b-form-input
                v-if="tocEdit.noteCustom"
                id="toc-notes"
                v-model="tocEdit.note"
                autofocus
                :state="(tocEdit.isAssign && tocEdit.note.length > 0) || !tocEdit.isAssign"
                trim
                placeholder=""
              />
              <v-select v-else v-model="tocEdit.note" :options="tocJustificationOptions" :clearable="false" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>

    <b-modal
      id="modal-status-reason"
      :ok-title="this.editMode.isReasonMissing ? 'Please Enter Reason' : 'Submit'"
      centered
      size="lg"
      title="Provide a Reason for Status Update"
      ref="modal"
      @hidden="() => (editMode.isStatusReasonOpen = false)"
      :visible="editMode.isStatusReasonOpen"
      @ok="updateSelectedProfilesStatus"
      :no-close-on-backdrop="true"
    >
      <b-row>
        <b-col>
          <b-form-group v-if="!['enrolled', 'graduated'].includes(editMode.status)" label="Reason" label-for="status-reason">
            <b-form-input
              id="transfer-reason"
              v-model="editMode.statusReason"
              autofocus
              :state="editMode.statusReason && editMode.statusReason.length > 0"
              trim
            />
          </b-form-group>
          <b-form-group label="Propagate" label-for="propagation-mode">
            <v-select
              id="propagation-mode"
              v-model="editMode.propagation.mode"
              :options="propagationOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              class="d-inline-block"
            />
          </b-form-group>
          <b-form-group v-if="'future' === editMode.propagation.mode" label="Effective As Of" label-for="propagation-effectiveAt">
            <b-form-datepicker id="propagation-effectiveAt" v-model="editMode.propagation.effectiveAt" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BLink,
  BMedia,
  BModal,
  BPagination,
  BRow,
  BTable,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { avatarText, gradeScore, title } from '@core/utils/filter';
import usePathwayCohortStudentsList from './usePathwayCohortStudentsList';
import moment from 'moment';

import SessionViewAddStudent from './PathwayCohortViewAddStudent.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import ProfileUploadDocument from '@/views/apps/roster/pathways/cohorts/view/components/ProfileUploadDocument.vue';
import StudentTransferToSession from '@/views/apps/student/students-view/components/StudentTransferToSession.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import VueContext from 'vue-context';
import useProfiles from '@/views/apps/roster/profile/useProfiles';
import useStudents from '@/views/apps/student/useStudents';
import PathwayCohortViewStudentsSectionList from './PathwayCohortViewStudentsSectionList.vue';
import ProfileListAddv2 from '../../../profile/create/ProfileListAddv2.vue';
import StudentAddCredit from '@/views/apps/student/students-view/components/StudentAddCredit.vue';
import StudentListStatuses from '@/views/apps/roster/pathways/cohorts/view/components/StudentListStatuses.vue';
import CompliancePopover from '@/views/apps/roster/courses/sections/view/components/CompliancePopover.vue';
import StudentPopover from '../../../courses/sections/view/components/StudentPopover.vue';
import { ref } from '@vue/composition-api';

export default {
  components: {
    BFormDatepicker,
    StudentTransferToSession,
    ProfileUploadDocument,
    SessionViewAddStudent,
    CompliancePopover,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BPagination,
    BCardBody,
    BCardTitle,
    BCardActions,
    BCardText,
    BModal,
    BTooltip,
    ToastificationContent,
    vSelect,
    VueContext,
    PathwayCohortViewStudentsSectionList,
    ProfileListAddv2,
    StudentAddCredit,
    StudentListStatuses,
    StudentPopover,
  },
  data: () => {
    return {
      isAddCreditOpen: false,
      createProfileActive: false,

      isTransferStudentOpen: false,
      selectedTransferProfile: {},
      editClassData: {},

      upload: {
        isUploadDocumentOpen: false,

        profile: undefined,
        documentType: undefined,
        documentCategory: undefined,
      },

      propagationOptions: [
        { label: 'None', value: 'none' },
        { label: 'Current', value: 'current' },
        { label: 'Current & Future', value: 'current_future' },
        { label: 'Future', value: 'future' },
        { label: 'All', value: 'all' },
      ],

      tocEdit: {
        enabled: false,
        isModalOpen: false,
        isAssign: true,

        profile: undefined,
        section: undefined,
        note: '',
        noteCustom: false,
      },

      defaultEditMode: { profile: null, status: null, statusReason: null, propagation: { mode: 'current_future', effectiveAt: null } },
      editMode: {
        selected: [],
        profile: null,
        status: null,
        statusReason: null,
        isStatusReasonOpen: false,
        isReasonMissing: false,
        propagation: { mode: 'current_future', effectiveAt: null },
      },
    };
  },
  props: {
    pathwayCohortData: { type: Object, required: true },
  },
  computed: {
    enrolledStudentIds() {
      return this.pathwayCohortData.profiles.map((profile) => profile.student.id);
    },
    isAllChecked() {
      return this.idsOnPage.filter((id) => this.editMode.selected.indexOf(id) === -1).length === 0;
    },

    tocSectionOptions() {
      return this.pathwayCohortData.sections
        .filter((section) => {
          if (this.selectedTransferProfile.sections && this.selectedTransferProfile.sections[section.id])
            return this.tocEdit.isAssign
              ? !this.selectedTransferProfile.sections[section.id].toc?.enabled
              : this.selectedTransferProfile.sections[section.id]?.toc?.enabled;

          return true;
        })
        .map((section) => {
          return { label: section.name, value: section.id };
        });
    },
  },
  methods: {
    gradeScore,
    redirectToReassigned(cohort) {
      if (cohort.type === 'course' && Array.isArray(cohort.sections) && cohort.sections.length > 0) {
        const id = cohort.sections[0].id ? cohort.sections[0].id : cohort.sections[0];
        return this.$router.push(`/roster/courses/sections/${id}`);
      }
      if (cohort.type === 'pathway') {
        const id = cohort.id ? cohort.id : cohort._id;
        return this.$router.push(`/roster/pathways/cohorts/${id}`);
      }
    },
    addStudent() {
      this.createProfileActive = true;
    },
    onRowContextMenu(item, index, event) {
      this.$refs.pathwayCohortViewStudentListMenu.open(event, { item });
      event.preventDefault();
    },
    editRosterProfile(profile) {
      // console.log(profile);
      this.$router.push({
        name: 'apps-roster-profile-edit',
        params: { id: profile.id },
      });
    },
    openCourseToCModal(profile, isAssign) {
      this.selectedTransferProfile = profile;
      this.tocEdit.isAssign = isAssign;
      this.$nextTick(() => (this.isAddCreditOpen = true));
    },
    submitProfileToC() {
      const { profile, isAssign } = this.tocEdit;
      const sectionId = this.tocEdit.section.value;
      profile.sections[sectionId].toc = isAssign
        ? {
            enabled: true,
            notes: this.tocEdit.note,
          }
        : { enabled: false };

      console.log(this.tocEdit, isAssign, profile.sections[sectionId]);

      (isAssign
        ? store.dispatch('rosterStoreModule/assignCourseCredit', {
            id: profile.id,
            creditData: { enabled: true, notes: this.tocEdit.note, sectionId },
          })
        : store.dispatch('rosterStoreModule/unassignCourseCredit', { id: profile.id, creditData: { enabled: false, sectionId } })
      )
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Credit assignment edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });

          this.tocEdit.section = undefined;
          this.tocEdit.note = '';
          this.refetchStudents();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    setAllChecked(val) {
      // if all of our current vals are selected and we're trying to update, noop
      if (val && this.isAllChecked) {
        return;
      }

      if (val) {
        // when checking, add all of the ids on the current visible page to our id buffer
        this.editMode.selected = this.editMode.selected.concat(this.idsOnPage).filter((id, index, items) => items.indexOf(id) === index);
      } else {
        // when checking, remove visible ids from the id list-pending
        this.editMode.selected = this.editMode.selected.filter((id) => this.idsOnPage.indexOf(id) === -1);
      }
    },
    isProfileSelected(profileId) {
      return this.editMode.selected.includes(profileId);
    },
    massSelectProfile(profileId, value) {
      if (value) {
        this.editMode.selected.push(profileId);
      } else {
        this.editMode.selected.splice(this.editMode.selected.indexOf(profileId), 1);
      }
    },
    updateProfileSelected(profileId, value) {
      if (value) {
        this.editMode.selected.push(profileId);
      } else {
        this.editMode.selected.splice(this.editMode.selected.indexOf(profileId), 1);
      }

      console.log(JSON.stringify(this.editMode.selected));
    },
    updateSelectedProfiles(profileIds, body) {},
    openReasonModal(profile, status) {
      // im lazy
      this.editMode.profile = profile;
      this.editMode.status = status;
      this.editMode.statusReason = null;
      this.editMode.propagation = { ...this.defaultEditMode.propagation };
      this.editMode.isStatusReasonOpen = true;
    },
    updateSelectedProfilesStatus(event, _profile, _status) {
      if (event) {
        event.preventDefault();
      }

      if (['withdrew_s1', 'withdrew_s2', 'withdrew_s3', 'terminated'].includes(status) && !this.editMode.statusReason) {
        this.editMode.isReasonMissing = true;

        const missingReasonTimeout = setTimeout(() => {
          this.editMode.isReasonMissing = false;
          return () => {
            clearTimeout(missingReasonTimeout);
          };
        }, 2000);

        return;
      }

      let profileIds = this.editMode.selected;
      const profile = this.editMode.profile;
      const status = this.editMode.status;
      const reason = this.editMode.statusReason;

      const pl = {
        status,
        statusReason: reason,
        statusDate: new Date(),
        options: {
          propagation: this.editMode.propagation,
        },
      };

      const doUpdate = () => {
        store
          .dispatch('rosterStoreModule/updateProfiles', {
            ids: profileIds,
            profileData: pl,
          })
          .then((response) => {
            this.refStudentsListTable.refresh();
            this.editMode.selected = [];
            this.editMode.status = null;
            this.editMode.statusReason = null;
            this.editMode.isStatusReasonOpen = false;
          });
      };

      if (!profileIds || profileIds.length === 0) {
        profileIds = [profile.id];
        doUpdate();
        return;
      }

      this.$swal({
        title: `Update ${profileIds.length} Profiles`,
        text: `You're setting ${profileIds.length} profiles to ${title(status)}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Update',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          doUpdate();
          console.log(`update following profiles to the ${status} status for reason "${reason}"`, profileIds);
        }
      });
    },
    openTransferSessionModal(profile) {
      this.selectedTransferProfile = profile;
      this.isTransferStudentOpen = true;
    },
    refetchStudents() {
      console.log('Fefetch');
      this.$emit('refetch-data');
      this.refetchData();
    },
    startUpload(profile, documentCategory, documentType) {
      this.upload = {
        isUploadDocumentOpen: true,
        profile,
        documentCategory,
        documentType,
      };
    },
    markProfileAsSubmitted(profile) {
      store
        .dispatch('rosterStoreModule/updateProfile', {
          id: profile.id,
          profileData: {
            attributes: { ...profile.attributes, submittedToVa: 'yes' },
          },
        })
        .then((response) => {
          this.refetchStudents();
        });
    },
    async removeFromCohort(cohortId, profileId) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to remove this student from this cohort?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const response = await this.$store
            .dispatch('rosterStoreModule/removeStudentsFromPathwayCohort', {
              cohortId,
              profileIds: [profileId],
            })
            .then(() => {
              this.refetchStudents();
            })
            .catch((error) => {
              console.error('failed to remove students to class', error);
            });
        }
      });
    },
  },
  watch: {
    'editMode.isStatusReasonOpen'(val) {
      console.log('> updating "editMode.isStatusReasonOpen">', val);
    },
    'tocEdit.note'(val) {
      if (val === 'Other') this.tocEdit.noteCustom = true;
    },
  },
  mounted() {},
  setup(props) {
    const studentListStatistics = ref(props.pathwayCohortData.statistics);

    const {
      perPage,
      currentPage,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refStudentsListTable,
      dataMeta,
      totalPageCount,

      refetchData,
      tableColumns,
      fetchProfiles,
      searchQuery,
      totalProfiles,
      idsOnPage,
      isCurrent,
    } = usePathwayCohortStudentsList(props.pathwayCohortData.id);

    const {
      resolveProfileStatusVariant,
      resolveProfileProgramVariant,
      resolveProfilePaymentStatusVariant,
      resolveProfileYNVariant,
      resolveProfileLocationStatus,
      resolveComplianceScoreVariant,
    } = useProfiles();

    const { resolveStudentStatusVariant } = useStudents();

    const tocJustificationOptions = ref([
      'Certification Earned <12 mo & Academic Experience',
      'Active Certification Earned<12 mo & Work Experience',
      'Active Certification w/ 10+ yrs Work Experience',
      'IP Alumni w/ Active Certification Earned <12 mo',
      'IP Alumni w/ Active Certification & 10+ yrs Work Experience',
      'Other',
    ]);

    return {
      // Sidebar

      fetchProfiles,
      refetchData,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refStudentsListTable,
      totalProfiles,
      idsOnPage,
      isCurrent,

      dataMeta,
      totalPageCount,

      moment,

      searchQuery,

      resolveProfileStatusVariant,
      resolveProfileProgramVariant,
      resolveProfilePaymentStatusVariant,
      resolveProfileYNVariant,
      resolveProfileLocationStatus,
      resolveStudentStatusVariant,
      resolveComplianceScoreVariant,

      tocJustificationOptions,
      studentListStatistics,

      // Filter
      avatarText,
      title,
    };
  },
};
</script>
<style scoped>
.table-min-height {
  min-height: 300px;
}
</style>
